import useEnableSelection from "../../../hooks/useEnableSelection";

import TableControls from "../../../components/Table/TableControls";
import HeadersDropdown from "../../../components/Dropdowns/HeadersDropdown";
import Pagination from "../../../components/Page/Pagination";

import { useState, Dispatch, SetStateAction } from "react";

import Redirect from '../../../assets/link.svg';
import EmptyTable from "../../../components/Table/EmptyTable";
import TableBase from "../../../components/Table/TableBase";
import TableRowBase from "../../../components/Table/TableRowBase";
import useModelsWithMethods from "../../../hooks/pageHooks/useModelsWithMethods";
import useModifyTableItems from "../../../hooks/useModifyTableItems";
import usePagination from "../../../hooks/usePagination";
import FilterPopup from "../../../components/Popups/FilterPopup";
import usePopup from "../../../hooks/usePopup";
import FilterButton from "../../../components/Buttons/FilterButton";
import { defaultAllModelsFilter } from "../../../data/models";

export default function AllModelsTable({ setSelectedModelNames, selectedModelNames }: { 
    setSelectedModelNames: Dispatch<SetStateAction<string[]>>, 
    selectedModelNames: string[] 
}) {
    const [ searchInput, setSearchInput ] = useState('');
    const [ selectedHeader, setSelectedHeader ] = useState('');

    const {
        headers,
        setHeaders,
        modifiedModels,
        modifyModels,
        allModelNames,
        isLoading,
        filter,
        filterFields,
        setFilter
    } = useModelsWithMethods();

    const {
        selectHeaders,
        setSortedColumn,
        applySearch,
        applyFilter
    } = useModifyTableItems({ modifyItems: modifyModels, setHeaders, searchInput, setSelectedHeader, selectedHeader, filter, setFilter })

    const { 
        editSelectedRows,
        selectAllRows,
        allAreSelected,
    } = useEnableSelection({allItems: allModelNames, modelNames: selectedModelNames, setModelNames: setSelectedModelNames});

    const { 
        currentPage, 
        setItemsPerPage, 
        paginatedItems, 
        paginate, 
        itemsPerPage 
    } = usePagination({items: modifiedModels, count: 10});

    const { 
        isPopupOpen,
        togglePopup,
        setPopupType,
    } = usePopup();



    return (
        <>
            <div className="mb-4">
                <TableControls 
                setInput={setSearchInput} 
                searchInput={searchInput} 
                applySearch={applySearch}
                identifier="Name"
                >
                    <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                    <FilterButton filter={filter} toggleFilterPopup={togglePopup} setPopupType={setPopupType}/>
                </TableControls>
            </div>

            <div className='max-h-[420px] flex flex-col mb-4'>
                {isLoading || paginatedItems.length > 0 ? (
                    <TableBase
                        isLoading={isLoading}
                        isPopupOpen={true}
                        isChecked={true}
                        headers={headers} 
                        selectedHeader={selectedHeader} 
                        setSortedColumn={setSortedColumn} 
                        selectAllRows={selectAllRows} 
                        allAreSelected={allAreSelected} 
                        hasOptions={isLoading ? false : true}
                    >
                        {paginatedItems.length > 0 && paginatedItems.map(item => (
                            <TableRowBase
                                item={item}
                                selectedRows={selectedModelNames} 
                                editSelectedRows={editSelectedRows}
                                headers={headers} 
                                isChecked={true}
                                rowType="base"
                            >
                                <label className="w-full h-full flex justify-end items-center">
                                    <a href={`${process.env.REACT_APP_HOST_URL}/models/${item.name}`} target="_blank" rel='noreferrer' className="p-1 rounded highlight transition-all mr-2">
                                        <img src={Redirect} alt='redirect-icon' className="w-[20px]" />
                                    </a>
                                </label>
                            </TableRowBase>
                        ))}
                    </TableBase>
                ) : (
                    <EmptyTable isPopup={true} message="Train a new model" subject="No models found" searchInput={searchInput} />
                )}
               
            </div>

            {paginatedItems.length > 0 && (
                <Pagination count={10} total={modifiedModels.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
            )}

                {isPopupOpen && (
                    <div id='nested-overlay' onClick={togglePopup as (e:any) => void} className='fixed top-0 right-0 left-0 bottom-0 bg-black/[0.3] z-50'>
                        <FilterPopup isNested={true} itemsFilter={filter} setItemsFilter={setFilter} searchInput={searchInput} togglePopup={togglePopup} applyFilter={applyFilter} defaultFilter={defaultAllModelsFilter} fieldsObj={filterFields} />
                    </div>
                )}
            
        </>   
    )
}