import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { SingleFileType } from "../../types/files.types";
import { SideInfo, Header } from "../../types/all.types";

import API from '../../api';
import { getIdentifier, isAdmin, isOwner, download, searchItems, sortByColumn } from "../../data/functions";
import { selectAuth } from "../../store/selectors/auth.selector";
import { updateExistingFile, deleteExistingFiles } from "../../store/slices/files.slice";
import { selectFiles } from "../../store/selectors/files.selectors";

const useSingleFile = ({ id, setPopupMessage }: {
    id?: string,
    setPopupMessage: (popupMessage: string, isSuccessMessage: boolean) => void,
}) => {
    const [ singleFile, setSingleFile ] = useState<SingleFileType | null>(null);
    const [ orderedRecords, setOrderedRecords ] = useState<any[]>([]);
    const [ modifiedRecords, setModifiedRecords ] = useState<any[]>([]);
    const [ info, setInfo ] = useState<SideInfo[]>([]);
    const [ routes, setRoutes ] = useState<{label: string, path: string}[]>([]);
    const [ headers, setHeaders ] = useState<Header[]>([]);
    const [ options, setOptions ] = useState<any>();
    const [ itemIdentifier, setItemIdentifier ] = useState('');



    const navigate = useNavigate();
    const dispatch = useDispatch();

    const auth = useSelector(selectAuth);
    const files = useSelector(selectFiles);

    const setupSingleFile = (singleFile: SingleFileType) => {
        setRoutes([
            {
                label: 'Files',
                path: 'files',
            },
            {
                label: singleFile ? singleFile.name : 'Single File',
                path: singleFile ? `files/${singleFile._id.$oid}` : '',
            }
        ]);


        setInfo([
            {
                label: 'File Name',
                value: singleFile.name ? singleFile.name : '',
                key: 'name',
                type: 'text',
            },
            {
                label: 'File Description',
                value: !singleFile.metadata.description ? 'No description' : singleFile.metadata.description,
                key: 'description',
                type: 'text-area',
            },
            {
                label: 'Project',
                value: !singleFile.metadata.project ? 'No project' :  singleFile.metadata.project,
                key: 'project',
                type: 'creatable-select',
            }, 
            {
                label: 'Measurement Type',
                value: !singleFile.metadata.measurement_type ? 'No measurement type' : singleFile.metadata.measurement_type,
                key: 'measurement_type',
                type: 'text',
            },
            {
                label: 'Organism',
                value: !singleFile.metadata.organism ? 'No organism' : singleFile.metadata.organism,
                key: 'organism',
                type: 'text',
            },
            {
                label: 'Target',
                value: !singleFile.metadata.target ? 'No target' : singleFile.metadata.target,
                key: 'target',
                type: 'text',
            },
            {
                label: 'Created By',
                value: singleFile.acl ? singleFile.acl.owner : '',
                key: auth && (isAdmin(auth) || isOwner(auth, singleFile.acl.owner)) ? 'owner' : undefined,
                type: 'select',
            },
            {
                label: 'Access Type',
                value: singleFile.acl ? singleFile.acl.access : '',
                key: auth && (isAdmin(auth) || isOwner(auth, singleFile.acl.owner)) ? 'access' : undefined,
                type: 'select',
            },
            
        ]);

        if (singleFile.records.length > 0) {

            const all = singleFile.records.map(r => ({ fields: r.fields, molecule: r.molecule.$oid }));
            const nonEmptyKeys = Object.keys(singleFile.records[0].fields).filter(key => singleFile.records[0].fields[key]);
            const fieldKeys = Object.keys(singleFile.records[0].fields);

            const identifier = getIdentifier(nonEmptyKeys, singleFile.fields_mapping);

            let orderedFields: string[];

            if (identifier) {
                setItemIdentifier(identifier);
                orderedFields = [identifier].concat(fieldKeys.filter(f => f !== identifier));
            } else {
                orderedFields = fieldKeys;
            }

            const fileHeaders = [{
                label: 'Structure',
                value: 'structure',
                isRequired: true,
                isAscending: true,
                isSelected: true,
                isSortable: false,
            }].concat(orderedFields.map((field, i) => ({
                label: field,
                value: field,
                isRequired: identifier && identifier === field ? true : false,
                isAscending: true,
                isSelected: true,
                isSortable: true,
            })))

            setHeaders(fileHeaders)

            let records: any[] = [];

            all.forEach(r => {
                let obj = {}
                fileHeaders.forEach(h => {
                    if (h.label !== 'Structure') {
                        obj = {...obj, [h.label]: r.fields[h.label] === undefined ? '' : r.fields[h.label]}
                    } else {
                        obj = {...obj, [h.label]: ''}
                    }
                })
                records.push({...obj, id: r.molecule});
            })

            console.log(records)
            setOrderedRecords(records);
            setModifiedRecords(records);

        } else {

            setOrderedRecords([]);
            setModifiedRecords([]);

        }
    }


    useEffect(() => {
        if (id) {
            const authKey = localStorage.getItem('X-Auth');
            if (authKey) {
                const fetchFile = async () => {

                    try {

                        const response = await API.get(`files/${id}`, { headers: { 'X-Auth': authKey } });
                        const data: SingleFileType = await response.data;
                        
                        setSingleFile(data);

                    } catch (err:any) {

                        console.log(err);
                        navigate('/files/404');

                    }   

                    
                };
        
                fetchFile();

            } else {
                navigate('/401');
            }
            
        }
        //eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (singleFile) {
            setupSingleFile(singleFile);
        }
        //eslint-disable-next-line
    }, [singleFile])

    useEffect(() => {
        if (files && files.length > 0 && Array.isArray(files)) {

            const projects = files.map(file => {
                if (file.metadata && file.metadata.project) {
                    return file.metadata.project
                } else {
                    return ''
                }
            });

            setOptions({
                project: Array.from(new Set(projects)).filter(a => a).map(p => ({value: p, label: p})),
            })   
        } 
    }, [files]);

    const modifyRecords = (searchInput: string, selectedHeader: string, updateHeaders?: Dispatch<SetStateAction<Header[]>>) => {
        let searchArr: any[] = [];
        if (itemIdentifier) {
            searchArr = searchItems(searchInput, orderedRecords, itemIdentifier);
        } else {
            searchArr = orderedRecords;
        }
        const sortedArr = sortByColumn(selectedHeader, headers, true, searchArr, updateHeaders);
        setModifiedRecords(sortedArr);
    }



    const updateFile = async (obj: any) => {
        const { name, description, project, measurement_type, organism, target } = obj;
        const aclObj = {owner: obj.owner, access: obj.access, read: [], write: []};
        const metadata = { name, description, project, measurement_type, organism, target };
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            try {

                await API.put(`files/${id}`, metadata, { headers: { 'X-Auth': authKey } });
                if (auth && singleFile && (isAdmin(auth) || isOwner(auth, singleFile.acl.owner))) {
                    await API.put(`files/${id}/acl`, aclObj, { headers: { 'X-Auth': authKey } })
                }
                const getResponse = await API.get(`files/${id}`, { headers: { 'X-Auth': authKey } });
                const data = getResponse.data;
                

                setSingleFile(data);
                dispatch(updateExistingFile(data));
                setPopupMessage('This file was updated successfully!', true);
    
            } catch(err:any) {
    
                console.log(err);
                setPopupMessage('There was an error updating this file', false)
    
            }
        }
    }
    


    const deleteFiles = async (ids: string[]) => {
        const authKey = localStorage.getItem('X-Auth');
        const promises = ids.map(async (id) => {
            return API.delete(`files/${id}`, { headers: { 'X-Auth': authKey } }).then(res => dispatch(deleteExistingFiles(id)));      
        })
        if (authKey) {
            try {
                await Promise.all(promises);
            } catch(err:any) {
                console.log(err);
                setPopupMessage(`There was an error deleting ${ids.length > 1 ? 'these files' : 'this file'}`, false);
                throw new Error('Error');
            }
        }
    };

    const downloadFile = async (filename: string, id: string, ext?: string) => {
        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {
                const response = await API.get(`files/${id}/download${ext !== undefined ? `?format=${ext}` : ''}`, { headers: { 'X-Auth': authKey }, responseType: 'blob' } );
                const data = await response.data;
                download(filename, data);
            } catch (err:any) {
                console.log(err);
                setPopupMessage('There was an error downloading this file', false)
            }
        }
    }


    return {
        orderedRecords,
        info,
        routes,
        singleFile,
        updateFile,
        options,
        deleteFiles,
        downloadFile,
        itemIdentifier,
        headers,
        setHeaders,
        modifiedRecords,
        modifyRecords,
    }


}

export default useSingleFile;